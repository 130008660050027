import { AccessRight } from "./accessrights";
import { throwError } from "./utils";

let prefix: string;
export function getPrefix() { return prefix ?? throwError(`routes: 'prefix' is not set`); }
export const setFrontend = () => prefix = '/api';
export const setApi = () => prefix = '';

export namespace general {
	export const settings = () => `${prefix}/settings`;
	export interface SettingsResult {
		loggedIn: boolean;
		priviledges: AccessRight[];
	}
}

export namespace auth {
	export const login = (backUrl?: string) => `${prefix}/login`
		+ ((backUrl == null) ? `` : `?${loginBackUrlParm}=${encodeURIComponent(backUrl)}`);
	export const loginBackUrlParm = 'back-url';

	export const logout = () => `${prefix}/logout`;
	export type LogoutResult = {};
}

export namespace test {
	export const getWho = (id: 1 | 2 | 3) => `${prefix}/who/${id}`;
	export type GetWhoResult = { who: 'world' | 'bob' | 'alice' };

	export const getHello = () => `${prefix}/hello`;
	export type GetHelloRequest = { who: string };
	export type GetHelloResult = { answer: string }

	export const setHello = () => `${prefix}/hello`;
	export type SetHelloRequest = { who: string };
	export type SetHelloResult = {};

	export const captcha = () => `${prefix}/captcha`;
	export type CaptchaRequest = {
		discardKey: string | null,
	};
	export type CaptchaResult = {
		key: string,
		svg: string,
	};

	export const sendForm = () => `${prefix}/inscription`;
	export type SendFormRequest = {
		nom: string,
		prenom: string,
		email: string,
		adresse: string,
		codePostal: number | null,
		viendra: boolean,
		accompagne: boolean,
		question: string,

		captchaKey: string,
		captchaText: string,
	};
	export type SendFormResult = { captchaFailed?: true };
}
